import React from 'react';
import { shape, element, func, string } from 'prop-types';
import { Provider } from 'react-redux';
import { compose, setDisplayName, setPropTypes, withContext } from 'recompose';
import { prop } from 'lodash/fp';

// The top-level React component setting context (global) variables
// https://facebook.github.io/react/docs/context.html
const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: func.isRequired,
  ...Provider.childContextTypes,
  i18n: func.isRequired,
  price: func.isRequired,
  contractPrice: func.isRequired,
  apiDomain: string.isRequired,
};

export default compose(
  setPropTypes({
    context: shape(ContextType).isRequired,
    children: element.isRequired,
  }),
  withContext(
    ContextType,
    prop('context'),
  ),
  setDisplayName(__filename),
)(({ children }) => React.Children.only(children),
);
