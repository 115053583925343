import { FRONTEND } from 'shared/constants/sites';

export default [
  {
    path: '',
    responsive: true,
    chunks: ['frontend/home'],
    site: FRONTEND,
    load: (mobileView) => {
      if (!mobileView) return import(/* webpackChunkName: 'frontend/home/web' */ 'frontend/modules/home/index');
      return import(/* webpackChunkName: 'frontend/home/mobile' */ 'frontend/modules/home/mobile-index');
    },
  },

  {
    path: '/auth',
    responsive: true,
    chunks: ['frontend/auth'],
    site: FRONTEND,
    children: true,
    load: (mobileView) => {
      if (!mobileView) return import(/* webpackChunkName: 'frontend/auth/web' */ 'frontend/modules/auth/index');
      return import(/* webpackChunkName: 'frontend/auth/mobile' */ 'frontend/modules/auth/mobile-index');
    },
  },

  {
    path: '/contract',
    responsive: true,
    chunks: ['frontend/contract'],
    site: FRONTEND,
    children: true,
    load: (mobileView) => {
      if (!mobileView) return import(/* webpackChunkName: 'frontend/contract/web' */ 'frontend/modules/contract/index');
      return import(/* webpackChunkName: 'frontend/contract/mobile' */ 'frontend/modules/contract/mobile-index');
    },
  },

  {
    path: '/account',
    responsive: true,
    chunks: ['frontend/account'],
    site: FRONTEND,
    children: true,
    load: (mobileView) => {
      if (!mobileView) return import(/* webpackChunkName: 'frontend/account/web' */ 'frontend/modules/account/index');
      return import(/* webpackChunkName: 'frontend/account/mobile' */ 'frontend/modules/account/mobile-index');
    },
  },

  {
    path: '/agency',
    responsive: true,
    chunks: ['frontend/agency'],
    site: FRONTEND,
    children: true,
    load: (mobileView) => {
      if (!mobileView) return import(/* webpackChunkName: 'frontend/agency/web' */ 'frontend/modules/agency/index');
      return import(/* webpackChunkName: 'frontend/agency/mobile' */ 'frontend/modules/agency/mobile-index');
    },
  },

  {
    path: '/article',
    chunks: ['frontend/article'],
    site: FRONTEND,
    children: true,
    load: () => import(/* webpackChunkName: 'frontend/article' */ 'frontend/modules/article'),
  },

  {
    path: '(.*)',
    chunks: ['frontend/not-found'],
    site: FRONTEND,
    load: () => import(/* webpackChunkName: 'frontend/not-found' */ 'frontend/modules/not-found'),
  },
];
