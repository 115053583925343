import { handleActions } from 'relient/reducers';
import { FRONTEND } from 'shared/constants/sites';
import {
  SET_SITE,
  SET_FEATURE,
  SET_FEATURE_GROUP,
  SET_USER_AGENT,
  READ_BALANCE,
} from 'shared/actions/global';

export default {
  global: handleActions({
    [READ_BALANCE]: (global, { payload }) => ({
      ...global,
      ...payload,
    }),

    [SET_SITE]: (global, { payload }) => ({
      ...global,
      site: payload,
    }),

    [SET_FEATURE]: (global, { payload }) => ({
      ...global,
      feature: payload,
    }),

    [SET_FEATURE_GROUP]: (global, { payload }) => ({
      ...global,
      featureGroup: payload,
    }),

    [SET_USER_AGENT]: (global, { payload }) => ({
      ...global,
      userAgent: payload,
    }),

  }, {
    site: FRONTEND,
    feature: null,
    featureGroup: null,
    userAgent: null,
  }),
};
