import { PUSH } from 'relient/actions/history';
import { prop, startsWith } from 'lodash/fp';
import { getFeatureBy } from 'shared/utils/feature';
import { getSiteBy } from 'shared/utils/site';

export default ({ getState }) => next => (action) => {
  const {
    payload,
    type,
  } = action;

  if (type === PUSH && !startsWith('.')(payload)) {
    const site = prop('global.site')(getState());
    const siteLink = getSiteBy('link')(site);
    let link = payload;
    if (!startsWith('/')(payload)) {
      link = getFeatureBy('link')(payload);
    } else if (siteLink) {
      link = `/${getSiteBy('link')(site)}${payload}`;
    }

    return next({
      ...action,
      payload: link,
    });
  }

  return next(action);
};
