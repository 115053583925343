import { createFeatureFor } from 'shared/utils/feature';
import { FRONTEND } from 'shared/constants/sites';

const createFeature = createFeatureFor(FRONTEND);

export const HOME = createFeature({ name: 'home', link: '' });
export const CONTRACT = createFeature({ name: 'contract', link: '/contract/contract' });
export const EARNING = createFeature({ name: 'earning', link: '/contract/order-and-earning' });
export const LOGIN = createFeature({ name: 'login', link: '/auth/login' });
export const REGISTER = createFeature({ name: 'register', link: '/auth/register' });
export const FORGET_PASSWORD = createFeature({ name: 'forgetPassword', link: '/auth/forget-password' });
export const MY_WALLET = createFeature({ name: 'myWallet', link: '/account/wallet' });
export const MY_WALLET_HISTORY = createFeature({ name: 'myWalletHistory', link: '/account/wallet-history' });
export const MY_AGENCY = createFeature({ name: 'myAgency', link: '/account/agency' });
export const RESEND_ACTIVATE_EMAIL = createFeature({ name: 'resendActivateEmail', link: '/auth/resend-activate-email' });
export const AGENCY_WALLET = createFeature({ name: 'agencyWallet', link: '/agency/wallet' });
export const AGENCY_DASHBOARD = createFeature({ name: 'agencyDashboard', link: '/agency/dashboard' });
export const AGENCY_AGENCY = createFeature({ name: 'myAgency', link: '/agency/agency' });
