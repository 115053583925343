import cookie from 'js-cookie';
import { includes } from 'lodash/fp';
import { ADMIN_AUTHORIZATION, FRONTEND_AUTHORIZATION } from 'shared/constants/cookies';
import {
  ADMIN_LOGIN,
  FRONTEND_LOGIN,
  ADMIN_LOGOUT,
  FRONTEND_LOGOUT,
} from 'shared/actions/auth';

export default () => next => (action) => {
  const { payload, type, meta } = action;
  if (includes(type)([ADMIN_LOGIN, FRONTEND_LOGIN])) {
    const { authorization } = payload;
    const cookieKey = type === ADMIN_LOGIN ? ADMIN_AUTHORIZATION : FRONTEND_AUTHORIZATION;
    if (meta.shouldRemember) {
      cookie.set(cookieKey, authorization, { expires: 60 });
    } else {
      cookie.set(cookieKey, authorization);
    }
  }
  if (includes(type)([ADMIN_LOGOUT, FRONTEND_LOGOUT])) {
    cookie.remove(
      type === ADMIN_LOGOUT ? ADMIN_AUTHORIZATION : FRONTEND_AUTHORIZATION,
    );
  }
  return next(action);
};
