import frontendRoutes from 'frontend/routes';

// The top-level (parent) route
// Keep in mind, routes are evaluated in order
const routes = [
  ...frontendRoutes,
];

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.unshift({
    path: '/error',
    // eslint-disable-next-line global-require
    action: require('./error').default,
  });
}

export default routes;
