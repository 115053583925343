import { getContext } from 'recompose';
import { func, string } from 'prop-types';
import { price as originalPrice } from 'relient/formatters';
import { JP, CN } from 'shared/constants/languages';
import { isNumber } from 'lodash/fp';
import { JPY, CNY, KRW } from 'shared/constants/currency-short-name';

export const getI18N = getContext({ i18n: func.isRequired });

export const getPrice = getContext({ price: func.isRequired });
export const price = ({ language }) => ({
  digit,
  defaultDisplay,
  currency,
  isElectricity = false,
} = {}) => (value) => {
  const { exchangeRate: { USDtoCNY, USDtoJPY } } = global;

  if (!isNumber(value)) {
    return defaultDisplay;
  }

  if ((language === JP && !currency) || currency === JPY) {
    if (isElectricity) {
      return originalPrice({ digit: 0 })(Math.round(value / (USDtoCNY / USDtoJPY)));
    }
    return originalPrice({ digit: 0 })(Math.round(value));
  }
  if ((language === CN && !currency) || currency === CNY) {
    return originalPrice({ digit })(value);
  }
  if (currency === KRW) {
    return originalPrice({ digit: 0, currency: '₩' })(Math.round(value));
  }
  if (isElectricity) {
    return originalPrice({ digit, currency: '$' })(value / USDtoCNY);
  }
  return originalPrice({ digit, currency: '$' })(value);
};

export const getContractPrice = getContext({ contractPrice: func.isRequired });
export const contractPrice = ({ language }) => ({ digit, defaultDisplay } = {}) => ({
  sellingPriceUSD,
  sellingPriceCNY,
  sellingPriceJPY,
  sellingPriceKRW,
} = {}, quantity = 1, currency) => {
  if (!isNumber(sellingPriceUSD)) {
    return defaultDisplay;
  }
  if (language === JP || currency === JPY) {
    return originalPrice({ digit })(Math.round(sellingPriceJPY * quantity));
  }
  if (language === CN || currency === CNY) {
    return originalPrice({ digit })(sellingPriceCNY * quantity);
  }
  if (currency === KRW) {
    return originalPrice({ digit, currency: '₩' })(Math.round(sellingPriceKRW * quantity));
  }
  return originalPrice({ digit, currency: '$' })(sellingPriceUSD * quantity);
};

export const getAPIDomain = getContext({ apiDomain: string.isRequired });
