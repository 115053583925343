let sites;

export const createSites = (data) => {
  sites = data;
};

export const getSiteBy = attribute => (site) => {
  if (!site) {
    return null;
  }
  return sites[site][attribute];
};
