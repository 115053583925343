import React from 'react';
import { string, object } from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import classNames from 'classnames';

export default compose(
  setPropTypes({
    type: string.isRequired,
    style: object,
    className: string,
    id: string,
  }),
  setDisplayName(__filename),
)(({ type, className, style, id }) => (
  <svg id={id} className={classNames('icon', className)} aria-hidden="true" style={style}>
    <use xlinkHref={`#vlinkbtc-${type}`} />
  </svg>
));
