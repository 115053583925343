import { post, actionTypeCreator, createAction } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const ADMIN_LOGIN = actionType('ADMIN_LOGIN');
export const FRONTEND_LOGIN = actionType('FRONTEND_LOGIN');
export const adminLogin = createAction(
  ADMIN_LOGIN,
  ({ username, password, captcha, type }) => post(
    `/auth/local?type=${type}`,
    { username, password, captcha, type },
    { headers: { 'x-auth-username': username, 'x-auth-password': password } }),
  ({ shouldRemember }) => ({ ignoreAuthRedirection: true, shouldRemember }),
);
export const frontendLogin = createAction(
  FRONTEND_LOGIN,
  ({ username, password, captcha, type }) => post(
    `/auth/local?type=${type}`,
    { username, password, captcha, type },
    { headers: { 'x-auth-username': username, 'x-auth-password': password } }),
  ({ shouldRemember }) => ({ ignoreAuthRedirection: true, shouldRemember }),
);

export const ADMIN_LOGOUT = actionType('ADMIN_LOGOUT');
export const FRONTEND_LOGOUT = actionType('FRONTEND_LOGOUT');
export const adminLogout = createAction(ADMIN_LOGOUT);
export const frontendLogout = createAction(FRONTEND_LOGOUT);

export const SET_AUTHORIZATION = actionType('SET_AUTHORIZATION');
export const setAuthorization = createAction(SET_AUTHORIZATION);

export const REMOVE_AUTHORIZATION = actionType('REMOVE_AUTHORIZATION');
export const removeAuthorization = createAction(REMOVE_AUTHORIZATION);
