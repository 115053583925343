import UniversalRouter from 'universal-router';
import { isFunction, isArray, prop, flow, includes } from 'lodash/fp';
import { getSiteBy } from 'shared/utils/site';
import { getEntity } from 'relient/selectors';
import { LOGIN, REGISTER } from 'frontend/constants/features';
import isMobileView from 'shared/utils/is-mobile-view';
import { setSite, setFeature, setFeatureGroup } from './actions/global';
import routes from './routes';

export default new UniversalRouter(routes, {
  async resolveRoute(context) {
    const {
      route,
      route: {
        load,
        action,
        onEnter,
        featureGroup,
        feature,
        site,
        requireAuth,
        component,
      },
      store: { dispatch, getState },
      params,
    } = context;

    const state = getState();

    if (getEntity('auth.isLogin')(state) && includes(feature)([LOGIN, REGISTER])) {
      return { redirect: '/' };
    }

    if (onEnter) {
      await onEnter(context, params);
    }
    if (featureGroup) {
      dispatch(setFeatureGroup(featureGroup));
    }
    if (feature) {
      dispatch(setFeature(feature));
    }
    if (site) {
      dispatch(setSite(site));
    }
    // NOTICE: should place after dispatch(setSite(site))
    if (requireAuth && !getEntity('auth.isLogin')(state)) {
      const siteLink = flow(prop('global.site'), getSiteBy('link'))(state);
      return { redirect: siteLink ? `/${siteLink}/auth/login` : '/auth/login' };
    }
    if (component) {
      return route;
    }
    if (isFunction(load)) {
      const module = await load(isMobileView(state));
      const result = await module.default(context);
      if (result.component) {
        return result;
      }
      if (isArray(result)) {
        route.children = result;
      }
    }
    if (isFunction(action)) {
      const result = await action(context);
      return { ...route, ...result };
    }
    return context.next();
  },
});
