import { combineReducers } from 'redux';
import { createEntitiesReducer } from 'relient/reducers';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import account from './account';
import global from './global';
import i18n from './i18n';
import contract from './contract';
import cryptocurrency from './cryptocurrency';
import withdrawal from './withdrawal';
import recharge from './recharge';
import wallet from './wallet';
import order from './order';
import log from './log';
import earning from './earning';
import agencyContract from './agency-contract';
import agency from './agency';
import walletHistory from './wallet-history';
import role from './role';
import accountContract from './account-contract';
import withdrawalNotification from './withdrawal-notification';
import statistics from './statistics';
import thirdPartyRecharge from './third-party-recharge';
import thirdPartyWithdrawal from './third-party-withdrawal';
import thirdPartyApplication from './third-party-application';
import summary from './summary';
import agencyPayment from './agency-payment';
import withdrawalRecord from './withdrawal-record';
import coupon from './coupon';
import reward from './reward';
import news from './news';
import transaction from './transaction';
import agencyWithdrawal from './agency-withdrawal';
import salesCommission from './sales-commission';
import withdrawalProcessingFee from './withdrawal-processing-fee';

export default combineReducers({
  ...createEntitiesReducer([
    auth,
    account,
    contract,
    cryptocurrency,
    withdrawal,
    wallet,
    order,
    log,
    earning,
    agencyContract,
    agency,
    walletHistory,
    role,
    accountContract,
    withdrawalNotification,
    recharge,
    thirdPartyRecharge,
    thirdPartyWithdrawal,
    thirdPartyApplication,
    agencyPayment,
    withdrawalRecord,
    coupon,
    reward,
    news,
    transaction,
    agencyWithdrawal,
    salesCommission,
    withdrawalProcessingFee,
  ]),
  ...statistics,
  ...summary,
  ...global,
  ...i18n,
  form: formReducer,
});
