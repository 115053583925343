import { actionTypeCreator, createAction, read } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const SET_SITE = actionType('SET_SITE');
export const setSite = createAction(SET_SITE);

export const SET_FEATURE_GROUP = actionType('SET_FEATURE_GROUP');
export const setFeatureGroup = createAction(SET_FEATURE_GROUP);

export const SET_FEATURE = actionType('SET_FEATURE');
export const setFeature = createAction(SET_FEATURE);

export const SET_USER_AGENT = actionType('SET_USER_AGENT');
export const setUserAgent = createAction(SET_USER_AGENT);

export const READ_BALANCE = actionType('READ_BALANCE');
export const readBalance = createAction(
  READ_BALANCE, () => read('/bitcoin/info'),
);
