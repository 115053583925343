import { propertyOf } from 'lodash/fp';
import { JP, EN, CN, KR } from './languages';

export const JPY = 'JPY';
export const USD = 'USD';
export const CNY = 'CNY';
export const KRW = 'KRW';

export const getCurrencyShortName = propertyOf({
  [JP]: JPY,
  [EN]: USD,
  [CN]: CNY,
  [KR]: USD,
});
