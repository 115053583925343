import { propertyOf, flow, includes, split, values, forEach, startsWith } from 'lodash/fp';
import { getSiteBy } from './site';

const separator = '_@_';
const createHashKey = ({ site, name }) => `${site}${separator}${name}`;

const features = {};

export const createFeatureFor = site => ({ name, link }) => {
  if (includes(separator)(name)) {
    throw new Error(`Name contains invalid characters ${separator}`);
  }
  if (!features[site]) {
    features[site] = {};
  }
  const hashKey = createHashKey({ site, name });
  features[site][name] = { name, link, key: hashKey };
  return hashKey;
};

export const getFeatures = flow(propertyOf(features), values);

export const getFeature = (feature) => {
  if (!feature) {
    return null;
  }
  const [site, name] = split(feature);
  return features[site][name];
};

const featureGroups = {};

export const createFeatureGroupFor = site => ({
  name,
  features: children,
  link,
  icon,
  roleKey,
}) => {
  if (includes(separator)(name)) {
    throw new Error(`Name contains invalid characters ${separator}`);
  }
  if (!featureGroups[site]) {
    featureGroups[site] = {};
  }
  const hashKey = createHashKey({ site, name });
  forEach((feature) => {
    features[site][split(separator)(feature)[1]].featureGroup = hashKey;
  })(children);
  featureGroups[site][name] = { name, features: children, link, icon, key: hashKey, roleKey };
  return hashKey;
};

export const getFeatureGroups = flow(propertyOf(featureGroups), values);

export const getFeatureGroup = (featureGroup) => {
  if (!featureGroup || !includes(separator)(featureGroup)) {
    return null;
  }
  const [site, name] = split(separator)(featureGroup);
  return featureGroups[site][name];
};

export const getFeatureGroupBy = attribute => (featureGroup) => {
  if (!featureGroup || !includes(separator)(featureGroup)) {
    return null;
  }
  const [site, name] = split(separator)(featureGroup);
  return featureGroups[site][name][attribute];
};

export const getFeatureBy = attribute => (feature) => {
  if (!feature || !includes(separator)(feature)) {
    return null;
  }
  const [site, name] = split(separator)(feature);
  if (attribute === 'link') {
    const { link, featureGroup } = features[site][name];
    if (!startsWith('/')(link) && featureGroup) {
      return `/${getSiteBy('link')(site)}/${getFeatureGroupBy('link')(featureGroup)}/${link}`;
    }
    return link;
  }
  return features[site][name][attribute];
};
