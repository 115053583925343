import { createStore, applyMiddleware, compose } from 'redux';
import {
  history as historyMiddleware,
  serverError,
} from 'relient/middlewares';
import { push } from 'relient/actions/history';
import { adminLogout, frontendLogout } from 'shared/actions/auth';
import { map, prop, propEq } from 'lodash/fp';
import reducers from 'shared/reducers';
import fetch from 'isomorphic-fetch/fetch-npm-browserify';
import fetchMiddleware from 'shared/middlewares/fetch';
import { FRONTEND } from 'shared/constants/sites';
import { Message } from 'antd';
import { error as frontendError } from 'frontend/components/message';
import authorization from './middlewares/authorization';
import history from './history';
import pushMiddleware from './middlewares/push';

const { __REDUX_DEVTOOLS_EXTENSION__, __INITIAL_STATE__ = {} } = global;

const middlewares = [
  fetchMiddleware({ fetch, apiDomain: `${global.location.origin}/api` }),
  authorization,
  pushMiddleware,
  historyMiddleware(history),
  serverError({
    onGlobalWarning: async ({ payload, getState }) => {
      let error;
      if (propEq('global.site', FRONTEND)(getState())) {
        error = frontendError;
      } else {
        error = Message.error;
      }
      error(map(prop('message'))(payload), 5);
    },
    onUnauthorized: ({ dispatch, getState }) => {
      if (propEq('global.site', FRONTEND)(getState())) {
        dispatch(frontendLogout());
      } else {
        dispatch(adminLogout());
      }
      dispatch(push('/auth/login'));
    },
  }),
];
let enhancer = applyMiddleware(...middlewares);

if (__DEV__) {
  // eslint-disable-next-line global-require
  middlewares.push(require('redux-logger').createLogger({ collapsed: true }));
  if (__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(...middlewares),
      __REDUX_DEVTOOLS_EXTENSION__(),
    );
  }
}

export default createStore(
  reducers,
  __INITIAL_STATE__,
  enhancer,
);
