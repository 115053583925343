import { createSites } from 'shared/utils/site';

export const ADMIN = 'ADMIN';
export const FRONTEND = 'FRONTEND';

createSites({
  [ADMIN]: {
    link: 'admin',
    name: 'Admin',
  },
  [FRONTEND]: {
    link: '',
    name: 'Frontend',
  },
});
