import React from 'react';
import Notification from 'rc-notification';
import { propertyOf } from 'lodash/fp';
import Icon from './icon';

const getIconType = propertyOf({
  success: 'round-check-fill',
  error: 'round-close-fill',
  warning: 'info-fill',
  info: 'info',
  loading: 'loading',
});

let defaultDuration = 3;
let defaultTop;
let messageInstance;
let key = 1;
let prefixCls = 'message';
let transitionName = 'move-up';
let getContainer;

function getMessageInstance(callback) {
  if (messageInstance) {
    callback(messageInstance);
    return;
  }
  Notification.newInstance({
    prefixCls,
    transitionName,
    style: { top: defaultTop }, // 覆盖原来的样式
    getContainer,
  }, (instance) => {
    if (messageInstance) {
      callback(messageInstance);
      return;
    }
    messageInstance = instance;
    callback(instance);
  });
}

function notice(
  content,
  duration = defaultDuration,
  type,
  onClose,
) {
  key += 1;
  const target = key;
  getMessageInstance((instance) => {
    instance.notice({
      key: target,
      duration: typeof duration === 'function' ? defaultDuration : duration,
      style: {},
      content: (
        <div className={`${prefixCls}-${type}`}>
          <Icon type={getIconType(type)} />
          <span>{content}</span>
        </div>
      ),
      onClose: typeof duration === 'function' ? duration : onClose,
    });
  });
  return () => {
    if (messageInstance) {
      messageInstance.removeNotice(target);
    }
  };
}

export const info = (content, duration, onClose) => notice(content, duration, 'info', onClose);
export const success = (content, duration, onClose) => notice(content, duration, 'success', onClose);
export const error = (content, duration, onClose) => notice(content, duration, 'error', onClose);
export const warning = (content, duration, onClose) => notice(content, duration, 'warning', onClose);
export const loading = (content, duration, onClose) => notice(content, duration, 'loading', onClose);
export const config = (options) => {
  if (options.top !== undefined) {
    defaultTop = options.top;
    messageInstance = null; // delete messageInstance for new defaultTop
  }
  if (options.duration !== undefined) {
    defaultDuration = options.duration;
  }
  if (options.prefixCls !== undefined) {
    prefixCls = options.prefixCls;
  }
  if (options.getContainer !== undefined) {
    getContainer = options.getContainer;
  }
  if (options.transitionName !== undefined) {
    transitionName = options.transitionName;
    messageInstance = null; // delete messageInstance for new transitionName
  }
};
export const destroy = () => {
  if (messageInstance) {
    messageInstance.destroy();
    messageInstance = null;
  }
};
